.modal {
  @apply fixed inset-0 z-[10000] w-screen h-screen bg-[#9B9B9B] bg-opacity-30 flex items-center justify-center transition-all duration-300 ease-in-out;
}

.modal-container {
  @apply p-3 w-[calc(100%-1rem*2)] bg-white rounded-2xl pb-8 text-white;
}

.modal[data-modal-size="xs"] .modal-container {
  width: min(400px, 100% - 1rem * 2);
}

.modal[data-modal-size="sm"] .modal-container {
  width: min(600px, 100% - 1rem * 2);
}

.modal[data-modal-size="md"] .modal-container {
  width: min(900px, 100% - 1rem * 2);
}

.modal[data-modal-size="lg"] .modal-container {
  width: min(1200px, 100% - 1rem * 2);
}

.open {
  @apply flex origin-bottom;
}

.close {
  @apply hidden;
}
