@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
}

canvas {
  touch-action: none;
}
::-webkit-scrollbar {
  background-color: transparent;
  width: 0px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: hsl(220, 98%, 61%);
}
.custom-scroll {
  @apply overflow-y-auto scrollbar-thin scrollbar-thumb-white scrollbar-track-transparent scrollbar-thumb-rounded-full scrollbar-track-rounded-full;
}

.custom-scroll-x {
  @apply overflow-x-auto scrollbar-thin scrollbar-thumb-slate-500 scrollbar-track-transparent scrollbar-thumb-rounded-full scrollbar-track-rounded-full;
}
.custom-scroll-x::-webkit-scrollbar {
  @apply h-2;
}

.custom-scroll::-webkit-scrollbar {
  @apply w-1;
}

.swiper-pagination-bullet {
  background: #646464 !important;
  opacity: 1 !important;
}
.swiper-pagination-bullet-active {
  background: #00ffdc !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important;
}
.select-field {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}
.select-dropdown {
  width: 102%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: absolute;
  top: -5%;
  left: -1%;
  filter: drop-shadow(0px 5px 30px rgba(43, 135, 218, 0.2));
  z-index: 1;
  background-color: white;
}
.hide-dropdown {
  display: none;
}
.select-dropdown > div {
  max-height: 270px;
  overflow-y: auto;
  margin: 13px;
  width: 100%;
}
.select-dropdown-item {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 10px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  width: auto;
  font-weight: 600;
  font-size: 13px;
  color: #4c5866;
}
.select-dropdown-item:hover {
  background: rgb(226, 225, 225);
  transition: all 0.3s ease-in-out;
}
.loader-container {
  background: rgb(55 65 81 / 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}
.loader {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid white;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.notification {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 7px 6px 10px;
  gap: 10px;
  min-width: 330px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 5px 30px rgba(43, 135, 218, 0.2);
  border-radius: 5px;
  margin-bottom: 10px;
  height: fit-content;
}
.notification-wrapper {
  position: fixed;
  top: 50px;
  right: 20px;
  min-width: 300px;
  width: max-content;
  z-index: 100;
}
.hidden {
  animation: SlideRight 0.4s;
  animation-fill-mode: forwards;
}
.open {
  animation: SlideLeft 0.4s;
  animation-fill-mode: forwards;
}
.notification-icon {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
}
@keyframes SlideLeft {
  0% {
    margin-left: 120%;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes SlideRight {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 120%;
  }
}
